<template>
	<section class="product-overview center">
		<div style="width:100%; max-width:1000px;">
			<quote-title label="百貨舖" />
			<br>
			<el-popover v-model="visible">
				<el-form :model="$data" ref="ruleForm">
					<el-form-item label="名稱" prop="name" :rules="{required:true,message:'必填',trigger:'change'}">
						<el-input v-model="name" placeholder="商品名稱" class="round" />
					</el-form-item>
					<el-form-item>
						<div class="row" align-x="right" align-y="center">
							<el-button @click="handleCancel()" type="text">取消</el-button>
							<el-button @click="handleCreate()" type="primary" size="mini">新增</el-button>
						</div>
					</el-form-item>
				</el-form>
				<template slot="reference">
					<el-button type="success" icon="el-icon-plus" round>新增</el-button>
				</template>
			</el-popover>
			<br>
			<br>
			<draggable class="row-grid" v-model="list">
				<div v-for="(item, i) in list" class="grid-item rwd-l-3 rwd-m-4 rwd-s-6" :key="item.id || i">
					<el-badge :value="item | badgeValueFilter" :type="item | badgeTypeFilter">
						<thumb :src="item.thumb" square />
						<br>
						<div class="col" align-x="left">
							<p v-if="item.name" style="font-size:1.3em;">{{item.name}}</p>
							<table>
								<tr>
									<th>定價</th>
									<td>{{item.price | currencyFilter}}</td>
								</tr>
								<tr>
									<th>椅友價</th>
									<td>{{item.special_price | currencyFilter}}</td>
								</tr>
							</table>
						</div>
						<br>
						<router-link :to="`/manager/product/${item.id}`">
							<el-button type="primary" size="mini" round>編輯</el-button>
						</router-link>
					</el-badge>
				</div>
			</draggable>
		</div>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
	components: {
		Draggable: () => import("vuedraggable"),
		Thumb: () => import("@/components/thumb")
	},
	data() {
		return {
			name: "",
			visible: false
		}
	},
	computed: {
		...mapState("product", ["_productList"]),
		list: {
			get() {
				return this._productList;
			},
			set(value) {
				this._sortList(value);
			}
		}
	},
	filters: {
		badgeValueFilter({ on_sale, amount }) {
			return !on_sale ? "下" : !amount ? "缺" : "";
		},
		badgeTypeFilter({ on_sale }) {
			return !on_sale ? "warning" : "danger";
		},
	},
	methods: {
		...mapActions("product", [
			"_getList",
			"_createData",
			"_sortList",
		]),
		async handleCreate() {
			this.$refs["ruleForm"].validate(async valid => {
				if (valid) {
					const { id, message } = await this._createData({ name: this.name });
					this.$message.success({ message });
					this.$router.push(`/manager/product/${id}`);
				}
			})
		},
		handleCancel() {
			this.name = "";
			this.$refs["ruleForm"].resetFields();
			this.visible = false;
		}
	},
	async created() {
		await this._getList();
	}
}
</script>

<style lang="scss" scoped>
.product-overview {
	table {
		text-align: left;

		td {
			padding-left: 1em;
			color: #cb2222;
		}
	}

	.el-badge {
		width: 100%;
	}

	.row-grid {
		margin: -10px;

		.grid-item {
			padding: 10px;
			cursor: move;
			cursor: grab;

			&:active {
				cursor: grabbing;
			}
		}
	}
}
</style>